import { render } from "./Ucapan.vue?vue&type=template&id=3e63411e"
import script from "./Ucapan.vue?vue&type=script&lang=js"
export * from "./Ucapan.vue?vue&type=script&lang=js"
script.render = render

export default script
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QScrollArea,QChatMessage,QInput,QBtn});
