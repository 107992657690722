import { render } from "./Home.vue?vue&type=template&id=be7f2146"
import script from "./Home.vue?vue&type=script&lang=js"
export * from "./Home.vue?vue&type=script&lang=js"

import "./Home.vue?vue&type=style&index=0&id=be7f2146&lang=scss"
script.render = render

export default script
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import Scroll from 'quasar/src/directives/Scroll.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSeparator,QPageSticky,QBtn});qInstall(script, 'directives', {Scroll});
