<template>
  <div class="bg-krem">
    <jumbotron @playMusic="onAudio" v-scroll-to="'#root'"></jumbotron>
    <template v-if="data.isReady">
      <mempelai id="root"></mempelai>

      <acara></acara>
      <lokasi></lokasi>
      <FormRSVP></FormRSVP>
      <q-separator></q-separator>
      <!-- <galeri></galeri> -->
      <q-separator></q-separator>
      <prokes></prokes>
      <div class="q-pa-md column items-center" style="background-color: #6c7d86;">
        <a class="text-center text-white">Kreasikan undangan virtualmu di</a>
        <a class="text-white" href="http://software.ligno.co.id/#/">www.software.ligno.co.id</a>
      </div>
      <q-page-sticky position="bottom-right" :offset="[18, 18]">
        <q-btn
          dense
          fab
          :icon="data.isOn ? 'volume_up' : 'volume_off'"
          color="accent"
          @click="playMusic"
        />
      </q-page-sticky>
    </template>
  </div>
</template>

<script>
import Jumbotron from "@/components/Jumbotron";
import Acara from "@/components/Acara";
import Mempelai from "@/components/Mempelai";
import Lokasi from "@/components/Lokasi";
import FormRSVP from "@/components/FormRSVP";
import Ucapan from "@/components/Ucapan";
import Galeri from "@/components/Galeri";
import Prokes from "@/components/Prokes";
import Cover from "@/components/Cover";

import { useQuasar } from "quasar";
import { ref, onMounted, reactive } from "vue";
export default {
  components: {
    Jumbotron,
    Acara,
    Mempelai,
    Lokasi,
    FormRSVP,
    Ucapan,
    Galeri,
    Prokes
  },
  setup() {
    const $q = useQuasar();
    const root = ref(null);

    const data = reactive({
      isOn: false,
      show_cover: true,
      isReady: false
    });

    let audio = new Audio(require("../assets/music/bg_2.mp3"));
    audio.preload = true;
    audio.loop = true;

    let playMusic = () => {
      data.isOn = !data.isOn;
      audio.muted = data.isOn;
    };
    let stopMusic = () => {
      audio.stop();
    };

    let onAudio = async () => {
      data.isReady = true;
      await audio.play();
    };

    return {
      playMusic,
      stopMusic,
      data,
      onAudio,
      root
    };
  }
};
</script>

<style lang="scss">
.bg-krem {
  background-blend-mode: hard-light;
  background-color: #f8fafb;
  // background-color: #6c7d86;
  background-image: url("https://www.transparenttextures.com/patterns/purty-wood.png");
}
</style>
