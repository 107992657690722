<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <div class="column bg-krem-2 items-center" style="height: 100vh;">
        <div class="col-4 text-center column justify-center">
          <p class="my-font text-bold text-h5">John Doe & Jane Doe</p>
          <p class="my-font text-h6">07 Juli 2021</p>
        </div>

        <q-img
          src="/img/img_1.jpg"
          fit="fill"
          style="border-radius:50%; max-width:75%"
          ratio="1"
        >
          <q-img
            src="/img/flower_2.png"
            fit="fill"
            style="position:absolute;z-index:99; background:transparent"
            ratio="1"
          ></q-img>
        </q-img>
        <q-btn
          rounded
          outline
          class="q-mt-xl"
          label="Buka Undangan"
          to="/home"
        ></q-btn>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { useQuasar, useDialogPluginComponent } from "quasar";
import { ref, onMounted } from "vue";
export default {
  setup() {
    const {
      dialogRef,
      onDialogHide,
      onDialogOK,
      onDialogCancel,
    } = useDialogPluginComponent();
    return {
      dialogRef,
      onDialogHide,

      onOKClick() {
        onDialogOK();
      },

      onCancelClick: onDialogCancel,
    };
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.bg-krem-2 {
  // background-color: #ebeaf1;
  background-color: #d7d9e5;
  background-image: url("https://www.transparenttextures.com/patterns/purty-wood.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
</style>
