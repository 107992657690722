<template>
  <div class="acara q-px-md q-py-xl" style="min-height: 100vh;">
    <q-dialog v-model="show.dialogAlamat" position="bottom">
      <q-card style="width: 350px">
        <q-card-section align="right">
          <div class="text-center text-h5 text-bold q-py-md my-font">Alamat</div>
          <q-btn class="absolute-right" v-close-popup flat icon="close" dense></q-btn>
        </q-card-section>
        <q-card-section>
          <q-input filled type="textarea" v-model="data.alamat" readonly></q-input>
        </q-card-section>
        <q-btn
          class="full-width no-border-radius"
          color="primary"
          label="copy alamat kirim"
          @click="copyAlamat"
        ></q-btn>
      </q-card>
    </q-dialog>

    <q-dialog v-model="show.dialogRekening" position="bottom">
      <q-card style="width: 350px">
        <q-card-section>
          <div class="text-center text-h5 text-bold q-py-md my-font">Rekening</div>
          <q-btn class="absolute-right" v-close-popup flat icon="close" dense></q-btn>
        </q-card-section>
        <q-card-section>
          <q-input filled type="textarea" v-model="data.rekening" readonly></q-input>
        </q-card-section>
        <q-btn
          class="full-width no-border-radius"
          color="primary"
          label="copy nomer rekening"
          @click="copyRekening"
        ></q-btn>
      </q-card>
    </q-dialog>

    <div class="text-center text-h5 text-bold q-py-md my-font">RSVP</div>
    <div class="row justify-evenly">
      <q-btn
        no-caps
        dense
        icon="local_atm"
        label="Kirim Angpao"
        color="lime-7"
        @click="show.dialogRekening = true"
      ></q-btn>
      <q-btn
        no-caps
        dense
        icon="cake"
        label="Kirim Kado"
        color="lime-7"
        @click="show.dialogAlamat = true"
      ></q-btn>
    </div>
    <div class="q-ma-md q-pa-md q-pt-lg bg-white" style="border-radius:15px 0 15px 0">
      <div class="text-center">
        <p>
          <span class="text-bold">{{ data.banyak }}</span> Tamu sudah memberikan
          Responya
        </p>
        <p>Ayo Berikan Respon Mu Juga!</p>
      </div>
      <hr />
      <div class="q-gutter-md q-mt-md">
        <div class="q-gutter-sm row items-center">
          <a>Pilih Avatar:</a>
          <q-btn round @click="data.tamu.ava = '2'">
            <q-avatar size="40px">
              <q-img src="/img/ava_2.png" fit="cover" style="border-radius:50%;">
                <div
                  v-if="data.tamu.ava != '2'"
                  class="absolute-full text-subtitle2 flex flex-center"
                ></div>
              </q-img>
            </q-avatar>
          </q-btn>
          <q-btn round @click="data.tamu.ava = '1'">
            <q-avatar size="40px">
              <q-img src="/img/ava_1.png" fit="cover" style="border-radius:50%;">
                <div
                  v-if="data.tamu.ava != '1'"
                  class="absolute-full text-subtitle2 flex flex-center"
                ></div>
              </q-img>
            </q-avatar>
          </q-btn>
          <q-btn round @click="data.tamu.ava = '3'">
            <q-avatar size="40px">
              <q-img src="/img/ava_3.png" fit="cover" style="border-radius:50%;">
                <div
                  v-if="data.tamu.ava != '3'"
                  class="absolute-full text-subtitle2 flex flex-center"
                ></div>
              </q-img>
            </q-avatar>
          </q-btn>
          <q-btn round @click="data.tamu.ava = '4'">
            <q-avatar size="40px">
              <q-img src="/img/ava_4.png" fit="cover" style="border-radius:50%;">
                <div
                  v-if="data.tamu.ava != '4'"
                  class="absolute-full text-subtitle2 flex flex-center"
                ></div>
              </q-img>
            </q-avatar>
          </q-btn>
        </div>
        <q-input
          dense
          class="bg-white"
          stack-label
          outlined
          label="Nama Tamu"
          v-model="data.tamu.nama"
        ></q-input>
        <q-select
          dense
          class="bg-white"
          stack-label
          outlined
          emit-value
          map-options
          label="Kehadiran"
          :options="data.opsiKehadiran"
          v-model="data.tamu.kehadiran"
        ></q-select>
        <q-input
          dense
          class="bg-white"
          outlined
          type="textarea"
          v-model="data.tamu.ucapan"
          label="pesan"
        ></q-input>
      </div>

      <q-btn class="full-width q-mt-md" color="positive" label="Kirim Respon" @click="add_rsvp"></q-btn>
    </div>
    <div class="q-ma-md q-pa-md q-pt-lg bg-white q-mt-xl">
      <div class="text-h5 text-bold text-center q-mb-lg my-font">Ucapan Para Tamu</div>
      <!-- <hr /> -->
      <q-scroll-area class="bg-grey-3 rounded-borders q-pa-sm" style="height: 200px;">
        <q-chat-message
          v-for="(val, i) in data.pesan"
          :key="i"
          :name="val.nama"
          :avatar="
            val.ava == '1'
              ? '/img/ava_1.png'
              : val.ava == '2'
              ? '/img/ava_2.png'
              : val.ava == '3'
              ? '/img/ava_3.png'
              : '/img/ava_4.png'
          "
          :text="[val.ucapan]"
          :stamp="val.created_at"
          class="full-width"
        />
      </q-scroll-area>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject, reactive } from "vue";
import moment from "moment";
import { copyToClipboard, Notify, useQuasar } from "quasar";

export default {
  setup() {
    const $http = inject("$http");
    const $q = useQuasar();

    const data = reactive({
      opsiKehadiran: [
        { label: "InsyaAllah datang", value: "DATANG" },
        { label: "Belum tentu bisa", value: "RAGU" },
        { label: "Maaf ga bisa hadir", value: "TIDAK" }
      ],

      tamu: {
        nama: "",
        kehadiran: "DATANG",
        id_costumer: "0877",
        jumlah: "1",
        asal: "",
        ucapan: "",
        ava: "2"
      },
      pesan: [],
      banyak: 0,
      alamat:
        "Penerima : Faridul Akhyar (0896-2826-4826)\nPerumahan Banjar Wijaya, Jalan Bambu Ori B50/38, Cluster Yunani,\nBanten, Tangerang, Cipondoh\n15148",
      rekening:
        "Bank Syariah Indonesia\nkode bank 451\nA.n Faridul Akhyar\n\n8050359940",
      rekening_short: "8050359940"
    });
    let show = reactive({
      dialogAlamat: false,
      dialogRekening: false
    });

    const virtualListRef = ref(null);
    const virtualListIndex = ref(1200);

    let add_rsvp = async () => {
      await $http.post("/reservasi/add_reservasi", data.tamu);
      await getucapan();
      data.tamu = {
        nama: "",
        kehadiran: "DATANG",
        id_costumer: "0877",
        jumlah: "1",
        asal: "",
        ucapan: "",
        ava: "2"
      };
    };

    let count_rsvp = async () => {
      let resp = await $http.get(`/reservasi/getlen/${data.tamu.id_costumer}`);
      data.banyak = resp.data.banyak;
    };

    let getucapan = async () => {
      let resp = await $http.get(`/ucapan/getlen/${data.tamu.id_costumer}`);
      data.pesan = resp.data;
      for (let item of resp.data) {
        item.created_at = moment(item.created_at).format("DD MMMM YYYY");
      }
    };

    let copyRekening = () => {
      copyToClipboard(data.rekening_short).then(() => {});
      $q.notify({
        message: "Nomer rekening berhasil dicopy",
        textColor: "teal-10",
        color: "positive"
      });
    };

    let copyAlamat = () => {
      copyToClipboard(data.alamat).then(() => {});
      $q.notify({
        message: "Alamat kirim berhasil dicopy",
        textColor: "primary",
        color: "positive"
      });
    };

    onMounted(count_rsvp(), getucapan());
    return {
      add_rsvp,
      count_rsvp,
      data,
      show,

      virtualListRef,
      virtualListIndex,
      copyRekening,
      copyAlamat
    };
  }
};
</script>
<style lang="scss" scoped></style>
