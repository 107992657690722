<template>
  <div
    class="column bg-krem-2 items-center justify-center full-width"
    style="height: 100vh;background: transparent;"
  >
    <q-img src="/img/cover_2.png"></q-img>
    <div class style="height:30%">
      <q-btn
        rounded
        v-if="!data.isOpen"
        class="q-mt-xl"
        label="Buka Undangan"
        color="lime-7"
        text-color="white"
        @click="playMusic"
      ></q-btn>
      <div v-else>
        <a class="arrow down"></a>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";
export default {
  setup(props, { emit }) {
    let data = reactive({
      isOpen: false
    });
    let playMusic = () => {
      emit("playMusic");
      data.isOpen = true;
    };

    return {
      playMusic,
      data
    };
  }
};
</script>

<style lang="scss" scoped>
@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(0.4em);
    opacity: 0.9;
  }
}

.arrow {
  border-color: transparent;
  border-style: solid;
  border-width: 0 2em;
  display: block;
  height: 0;
  margin: 10em auto;
  opacity: 0.4;
  text-indent: -9999px;
  transform-origin: 50% 50%;
  width: 0;
}

.down {
  -webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
  border-top: 2em solid #333333;
}
</style>
