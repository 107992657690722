<template>
  <div class="acara q-px-md q-py-xl" style="min-height: 100vh;">
    <div class="q-mt-xl q-gutter-lg">
      <div class="text-center">
        <q-img src="/img/bismillah_1.png" style="max-width:250px"></q-img>
      </div>
      <div class="text-center">
        <a>
          "Dan diantara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu
          pasangan hidup dari jenismu sendiri, supaya kamu mendapat ketenangan
          hati, dan dijadikan-Nya di antaramu rasa kasih dan sayang.
          Sesungguhnya yang demikian itu benar-benar terdapat tanda-tanda bagi
          kaum yang berfikir."
        </a>
        <p>(Qs. Ar-Ruum : 21)</p>
      </div>
      <div class="column text-center">
        <a
          class="text-bold q-mb-md"
          style="font-size:0.85rem"
        >Assalamualaikum Warahmatullahi Wabarakatuh</a>
        <a>Dengan memohon rahmat & ridho Allah SWT.,</a>
        <a>kami bermaksud mengundang Bapak/Ibu/Saudara/i dalam</a>
        <a>acara pernikahan putra-putri kami,</a>
      </div>
      <div class="text-center">
        <div class="column">
          <a class="my-font text-h5 text-lime-7 q-mb-md">Faridul Akhyar, S.T.P.</a>
          <a class="text-caption">Putra Bapak H. Tamamul Akhyar (Alm.) dan</a>
          <a class="text-caption">Ibu Zulmarni</a>
        </div>
        <p class="text-h6 q-my-lg">&</p>
        <div class="column">
          <a class="my-font text-h5 text-lime-7 q-mb-md">Dina Amalia, S.Pd.</a>
          <a class="text-caption">Putri Bapak H. Rakhmad Radian dan</a>
          <a class="text-caption q-mb-xl">Ibu Sri Suparni</a>
        </div>

        <div></div>
      </div>
    </div>
  </div>
</template>
