import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";

import VueLazyLoad from "vue3-lazyload";

import axios from "axios";

var VueScrollTo = require("vue-scrollto");

const app = createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(VueLazyLoad)
  .use(store)
  .use(router)
  .use(VueScrollTo, { duration: 2000, cancelable: false });

app.provide("$http",
  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
  })
);
app.mount("#app");
