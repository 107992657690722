<template>
  <div class="acara q-px-md q-py-xl">
    <div id class="q-pt-xl q-gutter-lg text-center" style="min-height: 100vh;">
      <div>
        <div>Insya Allah akan dilaksanakan pada:</div>
        <div class="text-h6 q-my-md">Sabtu, 02 Oktober 2021</div>
        <div class="row justify-evenly q-my-md">
          <div class="column justify-center">
            <a class="my-font text-h5 text-lime-7">Akad Nikah</a>
            <a class="text-caption">Pukul 08:00 - Selesai</a>
          </div>
          <!-- <div class="column justify-center">
            <a class="my-font text-h5 text-lime-7">Resepsi</a>
            <a class="text-caption">Pukul 10:00 - 13:00 WIB</a>
          </div> -->
        </div>
      </div>
      <div class="column">
        <a>
          di
          <strong>Masjid Nurul Haq</strong>
        </a>
        <a>Jalan Seduduk Putih 1 No.354, RW.07, Kel 8 Ilir, </a>
        <a>Kec Ilir Timur III, kota Palembang, Sumatera Selatan</a>
      </div>
      <div class="column">
        <a>Merupakan suatu kehormatan bagi kami apabila Bapak/Ibu/Saudara/i</a>
        <a>Berkenan hadir dan memberikan doa restu kepada kedua mempelai</a>
      </div>
      <div class="q-px-none">
        <a class="text-bold" style="font-size:0.82rem"
          >Wassalamualaikum Warahmatullahi Wabarakatuh</a
        >
      </div>
      <div>
        <a>Kami yang berbahagia,</a>
        <div class="column q-my-md q-gutter-md">
          <div class="column justify-center">
            <a>Kel. Bpk. H. Tamamul Akhyar (Alm.)</a>
            <a>Ibu Zulmarni</a>
          </div>
          <q-separator></q-separator>
          <div class="column justify-center">
            <a>Kel. Bpk. H. Rakhmad Radian</a>
            <a>Ibu Sri Suparni</a>
          </div>
        </div>
        <div class="q-pa-xl">
          <a class="my-font text-h5 text-lime-7">Farid & Dina</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
