<template>
  <div class="acara q-py-xl q-px-md" style="height: 100vh;">
    <div class="bg-white q-pa-md" style="height:400px">
      <div class="text-h5 text-bold text-center q-mb-lg my-font">Lokasi</div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.5366396291793!2d104.75786495078653!3d-2.9484587978420294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54505435f80077d%3A0x29b460203d811383!2sMasjid%20Nurul%20Haq%20-%20LDII!5e0!3m2!1sen!2sid!4v1631836285832!5m2!1sen!2sid"
        style="border:0;"
        width="100%"
        height="310px"
        allowfullscreen
        loading="lazy"
      ></iframe>
    </div>
  </div>
</template>
