<template>
  <div class="q-pa-md q-pt-lg" style="min-height: 100vh;">
    <div class="text-h5 text-bold text-center q-mb-lg my-font">Ucapan</div>
    <div>
      <q-scroll-area class="bg-white q-pa-sm" style="height: 200px;">
        <q-chat-message
          v-for="i in 5"
          :key="i"
          name="Cici dan Keluarga"
          avatar="https://cdn.quasar.dev/img/avatar4.jpg"
          :text="['Selamat Atas Pernikahan Nya']"
          stamp="28 Jul 2021"
          class="full-width"
        />
      </q-scroll-area>
    </div>
    <div class="q-mt-md bg-white q-pa-sm">
      <p class="text-center">Ayo Kirimkan Ucapan Mu Juga!</p>
      <hr />
      <div class="q-gutter-sm q-mt-md">
        <q-input
          class="bg-white"
          outlined
          label="pengirim"
          v-model="ucapan.pengirim"
        ></q-input>
      </div>
    </div>
    <q-btn
      color="primary"
      class="full-width q-mt-md"
      label="kirim pesan"
      @click="add_ucapan"
    ></q-btn>
  </div>
</template>

<script>
import { ref, inject } from "vue";
export default {
  setup() {
    let $http = inject("$http");

    let ucapan = ref({
      pengirim: "",
      pesan: "",
      id_costumer: "0877",
    });

    let add_ucapan = async () => {
      await $http.post("/ucapan/add_ucapan", ucapan._rawValue);
    };

    let getucapan = async () => {
      // let resp= await $http.
    };

    return {
      ucapan,
      add_ucapan,
    };
  },
};
</script>
