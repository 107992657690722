<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container style="min-height: 100vh;" class="row justify-center bg-leaf">
      <router-view style="width: 425px;" />
    </q-page-container>
  </q-layout>
</template>

<style lang="scss">
a,
div {
  font-family: "Times New Roman", Times, serif;
  color: #4a4648;
}
.bg-leaf {
  background-color: #b6b8be;
}
@font-face {
  font-family: customfont;
  src: url(./css/fonts/Monopola.ttf);
}

.my-font {
  font-family: "customfont";
  color: #646163;
}

.acara {
  background-image: url("/img/bg_3.png");
  background-repeat: none;
  background-size: cover;
}
</style>
