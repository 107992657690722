import { render } from "./FormRSVP.vue?vue&type=template&id=75a81865"
import script from "./FormRSVP.vue?vue&type=script&lang=js"
export * from "./FormRSVP.vue?vue&type=script&lang=js"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QBtn,QInput,QAvatar,QImg,QSelect,QScrollArea,QChatMessage});qInstall(script, 'directives', {ClosePopup});
