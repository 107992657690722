<template>
  <div class="q-pa-md q-pt-lg" style="min-height: 100vh;">
    <div class="text-h5 text-bold text-center q-mb-lg my-font">Galeri</div>
    <div class="row justify-center q-gutter-sm">
      <q-img
        v-for="(el, idx) in 9"
        :key="idx"
        :src="images[0].thumb"
        :ratio="1"
        style="width: 30%;"
      />
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  components: {},
  setup() {
    let images = ref([
      {
        thumb: "/img/thumb_1.jpg",
        src: "/img/img_1.jpg",
        caption: "image 1"
      }
    ]);

    return { images };
  }
};
</script>